<template>
    <div>
        <router-view v-if="$route.path.includes('nickname') || $route.path.includes('intro')"></router-view>
        <div class="background" v-else :key="key" id="edit-personal-box">
            <title-nav :title="'编辑资料'"/>
            <div class="edit-personal-box">
                <div class="title">
                    基本信息
                </div>
                <div>
                    <div class="panel">
                        <span class="text">昵称</span>
                        <span>{{ userInfo.nickname }}</span>
                        <router-link to="/edit-personal/nickname">
                            <img src="../../assets/images/enter.png" alt="" width="26" height="26">
                        </router-link>
                    </div>
                    <div class="panel">
                        <span class="text">简介</span>
                        <span>{{ userInfo.intro }}</span>
                        <router-link to="/edit-personal/intro">
                            <img src="../../assets/images/enter.png" alt="" width="26" height="26">
                        </router-link>
                    </div>
                    <div class="panel">
                        <span class="text">注册时间</span>
                        <span>{{ userInfo.createTime }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="logout" @click="logout">
                退出登录
            </div>
            <logout ref="logout"/> -->
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
import { queryUserByPhoneNum } from '@/api/api'
// import Logout from '../../components/dialog/Logout.vue'

export default {
    name: 'EditPersonal',
    components: {
        TitleNav,
        // Logout
    },
    created(){
        
    },
    activated(){

    },
    computed:{
        key(){
            this.getUserInfo()
            return this.$route.path + Math.random()
        }
    },
    mounted(){
        if(this.$store.state.userAgent !== 'Mobile'){
            document.getElementById("edit-personal-box").style.cssText= 'margin-top: 58px';
        }
    },
    data:()=>{
        return {
            userInfo:{
                nickname: JSON.parse(localStorage.getItem('userInfo'))[0].nickname,
                intro: JSON.parse(localStorage.getItem('userInfo'))[0].intro,
                createTime: JSON.parse(localStorage.getItem('userInfo'))[0].createdAt,
            }
        }
    },
    methods:{
        getUserInfo(){
            queryUserByPhoneNum({ phoneNum: localStorage.getItem('phoneNumber') }).then(res=>{
                localStorage.setItem('userInfo', JSON.stringify(res.data.data))
                this.userInfo.nickname = JSON.parse(localStorage.getItem('userInfo'))[0].nickname
                this.userInfo.intro = JSON.parse(localStorage.getItem('userInfo'))[0].intro
                this.userInfo.createTime = JSON.parse(localStorage.getItem('userInfo'))[0].createdAt
            })
        },
        // logout(){
        //     this.$refs.logout.show()
        // }
    },
}
</script>

<style scoped lang="scss">
.background {
    background: #f3f3f3;
    height: 100vh;
}
.edit-personal-box {
    background: #fdfdfd;
    margin: 10px auto;
    max-width: 625px;
    border: 1px solid rgba(0,0,0,0.1);
    .title {
        text-align: left;
        padding: 10px 20px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .panel{
        display: flex;
        align-items: center;
        padding: 10px 10px 10px 0px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        text-align: left;
        .text {
            width: 120px;
            min-width: 100px;
            padding: 0 0 0 20px;
        }
        :nth-child(2) {
            text-align: left;
            width: 100%;
            padding: 0 10px 0 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
        }
    }
}
.logout {
    background: #fdfdfd;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-top: 1px solid rgba(0,0,0,0.1);
}

</style>